import { BsCheckCircleFill } from "react-icons/bs";

const Content2 = () => {
    return(
        <div className="content2ProductBook">
            <h5 className="text-center fw-bold fs-30 m-fs-16 lh-160 color-blue">Apa Saja yang Akan Kamu Dapatkan ?</h5>
            <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 col-12 text-center mt-5 mt-lg-0 mt-md-0 mb-2 mb-lg-0 mb-md-0">
                </div>
                {/*  */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 ms-4 ms-lg-0 ms-md-0">
                    <div className="row mt-0 mt-lg-3 mt-md-3">
                        {/* 1 */}
                        <div className="col-lg-12 col-md-12 col-sm-6 col-6">
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1 mt-0 mt-lg-1 mt-md-1">
                                    <BsCheckCircleFill size={25} style={{"color": "#FF9E20"}} className='d-none d-sm-block' />
                                    <BsCheckCircleFill size={16} style={{"color": "#FF9E20"}} className='d-block d-sm-none' />
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-10 ms-0 ms-lg-3 ms-md-3 fw-bold fs-22 m-fs-12 lh-160 dark-text nowrap">
                                    Materi Asesmen Nasional
                                </div>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="col-lg-12 col-md-12 col-sm-6 col-6">
                            <div className="row my-0 my-lg-5 my-md-5">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1 mt-0 mt-lg-1 mt-md-1">
                                    <BsCheckCircleFill size={25} style={{"color": "#FF9E20"}} className='d-none d-sm-block' />
                                    <BsCheckCircleFill size={16} style={{"color": "#FF9E20"}} className='d-block d-sm-none' />
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-10 ms-0 ms-lg-3 ms-md-3 fw-bold fs-22 m-fs-12 lh-160 dark-text">
                                    Latihan Soal
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mt-lg-0 mt-md-0">
                        {/* 3 */}
                        <div className="col-lg-12 col-md-12 col-sm-6 col-6">
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                    <BsCheckCircleFill size={25} style={{"color": "#FF9E20"}} className='d-none d-sm-block' />
                                    <BsCheckCircleFill size={16} style={{"color": "#FF9E20"}} className='d-block d-sm-none' />
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-10 ms-0 ms-lg-3 ms-md-3 fw-bold fs-22 m-fs-12 lh-160 dark-text nowrap">
                                    Pembahasan Lengkap
                                </div>
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="col-lg-12 col-md-12 col-sm-6 col-6">
                            <div className="row my-0 my-lg-5 my-md-5">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1 mt-0 mt-lg-1 mt-md-1">
                                    <BsCheckCircleFill size={25} style={{"color": "#FF9E20"}} className='d-none d-sm-block' />
                                    <BsCheckCircleFill size={16} style={{"color": "#FF9E20"}} className='d-block d-sm-none' />
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-10 ms-0 ms-lg-3 ms-md-3 fw-bold fs-22 m-fs-12 lh-160 dark-text">
                                    Simulasi Ujian
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content2