const Content4 = () => {
    return (
        <div className="content4ProductBook mx-auto d-block text-center mb-5 mt-5">
            <h6 className="text-white fw-bold fs-20 m-fs-8 lh-160">Ingin belajar tentang  materi pembelajaran <br /> Asesmen Nasional 2022 ?</h6>
            <h5 className="fs-30 m-fs-14 text-white fw-bold lh-160">Buku Panduan Asesmen <br /> Nasional 2022</h5> <br className="d-none d-sm-block" />
            <h6 className="fw-bold fs-20 m-fs-8 lh-160 text-white">Pesan dan belajar bersama Eureka Edutech !</h6>
        </div>
    )
}

export default Content4