const Content2 = () => {
    return(
        <div className="content2">
            <h6 className="fw-bold fs-30 m-fs-16 color-blue text-center pt-5">Kenapa Harus Eureka Edutech ?</h6>
                <div className="d-flex flex-column flex-md-row">
                    {/* Left Box */}
                    {/* First */}
                    <div className="col-lg-4 content2-box1">
                        <div className="box1 row g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 dark-text fw-bold ms-4 mt-3 lh-160">Kurikulum Terkini</h6>
                            <p className="text-grey ms-4 lh-160 m-fs-12">Materi dan bank soal yang disesuaikan 
                                <br />
                                    dengan kurikulum terkini dari Pusmenjar</p>
                        </div>
                        {/* Second */}
                        <div id="content2-box2" className="row g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 m-fs-12 dark-text fw-bold ms-4 mt-3 lh-160">Materi Lengkap</h6>
                            <p className="text-grey ms-4 m-fs-14 m-fs-12 lh-160 text-justify">Materi terlengkap, contoh soal, latihan 
                                <br />
                                    soal, dan ujian yang dapat 
                                <br /> 
                                    meningkatkan pemahaman untuk 
                                <br /> 
                                    persiapan mengikuti Asesmen Nasional</p>
                        </div>
                    </div>
                    {/* Right Box */}
                    {/* Third */}
                    <div className="col-lg-4 content2-box3">
                        <div className="box3 row g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 m-fs-12 dark-text fw-bold ms-4 mt-3 lh-160">Aplikasi Ringan</h6>
                            <p className="text-grey ms-4 m-fs-14 m-fs-12 lh-160">Aplikasi ringan dan sangat mudah
                                <br /> 
                                    digunakan
                            </p>
                        </div>
                        {/* Fourth */}
                        <div id="content2-box4" className="row g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 m-fs-12 dark-text fw-bold ms-4 mt-3 lh-160">Pembahasan Detail</h6>
                            <p className="text-grey ms-4 m-fs-14 m-fs-12 lh-160">Khusus membahas Asesmen Nasional 
                                <br />
                                    dengan materi dan bank soal yang lebih 
                                <br /> 
                                    fokus dan paling detail</p>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Content2