import { Link } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";

const Content1 = () => {
    return(
        <div>
            <h5 className="gradient fw-bold white-text fs-30 m-fs-18 lh-160 text-center pt-lg-5 pt-md-5">Kontak Kami</h5>
            <div className="content1Contact mt-3">
                <Link to='/' className="text-decoration">
                    <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 text-grey linkHome">Beranda</h6>
                </Link>
                <BsFillCaretRightFill className="text-grey mx-2" size={ 12 } />
                <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 blue-text">Kontak Kami</h6>
            </div>
        </div>
    )
}

export default Content1