const Content3 = () => {
    return(
        <div className="content3Terms mt-4">
            <h6 className="fw-bold fs-22 m-fs-16 lh-160">Syarat dan Ketentuan Penggunaan Aplikasi Eureka Edutech</h6>
            <div className="fs-16 m-fs-12 lh-160 fw-500 text-grey">
                <b className="m-fs-12">EUREKA </b>adalah produk yang dikembangkan, dimiliki serta dikelola oleh PT. Edukasi Rekanan Anda. <b className="m-fs-12">EUREKA </b>merupakan sebuah layanan edukasi berupa informasi dalam bentuk aplikasi perangkat lunak ("Platform"). 
                
                <br /> <br />

                Dengan melakukan unduhan, memasang, dan/atau menggunakan Platform ini, dengan kata lain Anda telah menyetujui bahwa Anda telah
                membaca, memahami, dan menyetujui seluruh informasi, syarat-syarat, dan ketentuan-ketentuan penggunaan. Adapun dibuatnya Syarat 
                dan Ketentuan, dengan tujuan untuk menandakan bahwa adanya perjanjian hukum antara Pengguna ("Anda") serta dengan <b className="m-fs-12">EUREKA</b>.
                Namun, harap diperhatikan bahwa syarat ketentuan dan Kebijakan Privasi akan terjadi perubahan dari waktu ke waktu serta hasil dari
                perubahan yang akan terjadi akan kami beritahukan kepada Anda pada saat anda melakukan akses pada Platform. 
                
                <br /> <br />

                <b className="m-fs-12">Anda diharapkan untuk menghapus Platform dalam perangkat Anda, Anda dapat melakukan permohonan untuk penghapusan akun,
                serta Anda sangat tidak disarankan untuk mengakses platform jika Anda tidak setuju terhadap Syarat dan Ketentuan Penggunaan ini.</b> <br /> <br />
                {/*  */}
                <b className="m-fs-12">Ketentuan Umum</b>
                <ol type="A" className="inline-block-list m-fs-12">
                    <li className="my-2 m-fs-12">Semua kata pengganti di bawah Syarat dan Ketentuan ini untuk "Anda" merujuk pada Pengguna Aplikasi ("User"), untuk "EUREKA" dan
                        "kami" merujuk pada PT. Edukasi Rekanan Anda.</li>
                    <li className="my-2 m-fs-12">Koneksi internet menjadi kebutuhan utama yang diperlukan untuk dapat melakukan akses pada semua layanan yang ada. Segala
                        bentuk biaya yang terjadi akibat dari penggunaan aplikasi merupakan tanggung jawab pengguna sepenuhnya.</li>
                    <li className="my-2 m-fs-12">Beberapa fitur yang ada pada Aplikasi ini adalah:</li>
                    <ol type="i">
                        <li className="my-1 m-fs-12">Artikel Bacaan</li>
                        <li className="my-1 m-fs-12">Materi dan bank soal</li>
                        <li className="my-1 m-fs-12">Aktivitas pengguna</li>
                        <li className="my-1 m-fs-12">Layanan lain apapun yang dapat kami tambahkan dari waktu ke waktu, (masing-masing disebut <b className="m-fs-12">"Layanan"</b> dan secara kolektif
                        disebut <b className="m-fs-12">"Layanan-Layanan"</b>)</li>
                    </ol>
                    <li className="my-2 m-fs-12">Platform hanya merupakan sebuah sarana untuk memudahkan dalam menggunakan aplikasi pendidikan yang sudah dikembangkan.</li>
                    <li className="my-2 m-fs-12">Kami adalah perusahaan teknologi pendidikan. Kami tidak mempekerjakan Penyedia Layanan, dan kami tidak bertanggung jawab atas
                        tindakan, kecerobohan, kelalaian, dan/atau kelengahan Penyedia Layanan. Platform merupakan layanan informasi dan komunikasi
                        online yang disediakan oleh EUREKA.</li>
                </ol> 
                <br />
                {/*  */}
                <b className="m-fs-12">Ketentuan Penggunaan Aplikasi</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">
                        Dengan mengakses platform/ menggunakan layanan, Anda menyatakan dan menjamin terkait data pribadi Anda yang dipublikasikan 
                        atau tampilkan di platform bersifat data akurat. Apabila data yang diberikan tidak akurat, Kami berhak untuk membatalkan akun Anda 
                        atau menghapus akun Anda di database Kami.</li>
                    <li className="my-2 m-fs-12">
                        Dengan Anda mengunduh platform, Anda setuju untuk mematuhi semua panduan, pemberitahuan dan kebijakan terkait penggunaan 
                        layanan akses ke platform dan Anda menyetujui untuk melakukan aktivitas apapun terkait layanan dengan cara yang baik.</li>
                    <li className="my-2 m-fs-12">
                        Akses ke platform dilindungi oleh kata sandi. Maka Anda menyatakan dan menjamin untuk tidak memberikan kata sandi akun Anda ke 
                        siapapun yang dapat mengakses platform dengan data diri Anda. Apabila Anda memberikan wewenang ke orang lain untuk mengakses 
                        platform, Kami tidak bertanggung jawab atas penggunaan yang melanggar aturan karena aplikasi mendata akun tersebut milik Anda. 
                        Kecuali Anda memberikan informasi terkait akun Anda.</li>
                    <li className="my-2 m-fs-12">
                        Anda menyatakan bahwa akan menggunakan platform ini hanya untuk memperoleh layanan. Anda tidak diperkenankan untuk 
                        menyalahgunakan platform atau mengubah dengan cara apapun. Kami berhak untuk menutup akun Anda atau tidak diperkenankan 
                        mendaftarkan kembali dengan data diri Anda apabila Anda melakukan aktivitas yang menyalahi aturan Kami.</li>
                </ol>
                <br />
                {/*  */}
                <b className="m-fs-12">Layanan dan Fitur</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">Artikel Bacaan</li>
                        <ol type="i">
                            <li className="my-1 m-fs-12">Fitur ini memfasilitasi pengguna dengan artikel seputar literasi dan numerasi</li>
                            <li className="my-1 m-fs-12">Anda dapat mengetahui artikel terbaru dari Eureka dan program menarik dari EUREKA</li>
                        </ol>
                    <li className="my-2 m-fs-12">Materi dan bank soal</li>
                        <ol type="i">
                            <li className="my-1 m-fs-12">Fitur ini berisi kumpulan materi pelajaran serta tips dan trik pembahasan dalam memecahkan soal</li>
                            <li className="my-1 m-fs-12">Fitur ini juga berisi kumpulan bank soal sebagai bahan latihan dengan jenis soal yang bervariasi</li>
                        </ol>
                    <li className="my-2 m-fs-12">Aktivitas Pengguna</li>
                        <ol type="i">
                            <li className="my-1 m-fs-12">Fitur ini merekam jejak aktivitas pengguna saat menggunakan Platform EUREKA</li>
                        </ol>
                </ol>
                <br />
                {/*  */}
                <b className="m-fs-12">Pembatasan Tanggung Jawab</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">Aplikasi tidak bertanggung jawab atas kondisi posisi pengguna.</li>
                    <li className="my-2 m-fs-12">Aplikasi tidak bertanggung jawab atas tindakan yang diambil pengguna setelah menggunakan layanan fitur aplikasi.</li>
                    <li className="my-2 m-fs-12">Aplikasi tidak bertanggung jawab atas biaya yang dikeluarkan oleh pengguna setelah menggunakan layanan fitur aplikasi.</li>
                    <li className="my-2 m-fs-12">Aplikasi tidak bertanggung jawab atas ketidaksesuaian materi yang diberikan pada Platform dengan ekspektasi pengguna.</li>
                </ol>
                <br />
                {/*  */}
                <b className="m-fs-12">Pemberitahuan</b> <br />
                Semua pemberitahuan dan/atau permintaan informasi kepada dan/atau yang berkaitan dengan kami akan diproses secara tertulis dan 
                untuk dapat dikirimkan ke alamat kami di <b className="m-fs-12">Graha Satria 1 Suite 203, Jalan RS Fatmawati No. 5 RT.01/RW.09, Cilandak Barat, Jakarta 
                Selatan, Jakarta 12430 </b> atau alamat email kami di : <b className="m-fs-12">eureka.learningplatform@gmail.com</b> atau call center di <b className="m-fs-12">(021) 3451737</b>.
                <br />
                <br />
                {/*  */}
                <b className="m-fs-12">Keamanan Aplikasi</b> <br />
                Kami berusaha untuk menjaga sistem aplikasi kami agar dapat berjalan dan berfungsi dengan baik. Anda memahami bahwa teknologi saat ini sudah sangat mumpuni untuk menyusup ke dalam sistem kami. Karenanya setiap pesan atau informasi pribadi mungkin dapat dibajak dengan mudah. Oleh karena itu, kami selaku pembuat serta pemilik selalu melakukan pembaruan keamanan untuk sistem guna bertujuan untuk mencegah adanya tindak kriminal yang dilakukan oleh pihak-pihak yang tidak bertanggung jawab yang dapat merugikan kami dan juga anda.
                <br />
                <br />
                {/*  */}
                <b className="m-fs-12">Onesignal</b> <br />
                Kami menggunakan data email dan/atau nomor telepon user untuk mengirimkan notifikasi kepada pengguna.
                <br />
                <br />
                {/*  */}
                <b className="m-fs-12">Penutup</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">
                        Hubungan yang terjadi antara pengguna dengan kami bukan merupakan sebuah hubungan dalam bentuk keagenan, kemitraan, usaha 
                        patungan, karyawan-perusahaan atau pemilik waralaba-pewaralaba melainkan suatu hubungan independen.</li>
                    <li className="my-2 m-fs-12">
                        Judul yang ada pada Ketentuan Penggunaan yang dibuat hanya bertujuan sebagai acuan sebagai syarat dan ketentuan pada aplikasi ini.
                    </li>
                    <li className="my-2 m-fs-12">
                        Kami memiliki hak untuk menginvestigasi dan menuntut hak Kami untuk setiap pelanggaran atas Ketentuan Penggunaan ini sepanjang 
                        yang dimungkinkan dan diperkenankan oleh hukum. Anda dengan ini mengakui bahwa Kami memiliki hak untuk memonitor akses 
                        penggunaan situs dan Aplikasi untuk memastikan kepatuhan dengan Ketentuan Penggunaan ini, atau untuk mematuhi peraturan yang 
                        berlaku atau perintah atau persyaratan dari pengadilan, lembaga administratif atau badan pemerintahan lainnya.
                    </li>
                    <li className="my-2 m-fs-12">
                        Pengguna aplikasi sangat tidak diperbolehkan untuk melakukan pencucian uang dan/atau menggunakan uang yang memiliki sumber 
                        yang menurut hukum tidak sah. Kami berhak untuk melaporkan segala bentuk transaksi yang dirasa patut dicurigai sebagai tindak 
                        pidana pencucian uang kepada pihak yang berwajib.
                    </li>
                    <li className="my-2 m-fs-12">
                        Jika dikemudian hari ditemukan tindakan manipulasi/kecurangan, seperti menutup atau membatalkan akun, menarik subsidi, menarik 
                        cashback, membatalkan transaksi, menahan dana, serta hal-hal lainnya jika ditemukan indikasi kecurangan, pencucian uang atau 
                        pelanggaran yang dilakukan oleh pengguna. Kami berhak dan tanpa memberikan pemberitahuan sebelumnya untuk melakukan 
                        tindakan-tindakan yang diperlukan sesuai dengan ketentuan hukum yang berlaku pada wilayah negara Republik Indonesia.
                    </li>
                </ol>
            </div>
        </div>
    )
}

export default Content3