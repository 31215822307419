import Content3Mobile from "./Content-3-mobile";
import { BsCheckCircleFill } from "react-icons/bs";
import Recommend from '../../../assets/images/Recommend.svg'

const Content3 = () => {
    const isMobile = window.innerWidth <= 500

    if(isMobile) {
        return(
            <Content3Mobile />
        )
    }else {
        return(
            <div>
                <h6 className="text-center color-blue fw-bold fs-30 lh-160 my-5">Langganan Eureka Edutech dan Nikmati Benefitnya</h6>
                <div className="d-flex justify-content-center">
                    {/* Starter Pack */}
                    <div className="starter-pack">
                        <h6 className="fw-bold fs-22 lh-160 dark-text text-center mb-4">Starter Pack</h6>
                        <div className="fw-bold line-through fs-16 lh-160 dark-text">Rp 120.000</div>
                        <h5 className="text-orange fw-bold d-inline-block fs-30 lh-160 mb-4">Rp 95.000</h5>
                        <small className="dark-text fw-bold fs-16 lh-160"> / 1 bulan</small>
                        <h5 className="fw-bold fs-16 lh-160 dark-text">Yang Didapatkan :</h5>
                        {/* 1 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses semua materi tanpa batas
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Latihan soal dan pembahasan gratis
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Asah skillmu di kuis harian sebebasnya
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses simulasi ujian 1 Bulan
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses webinar Kumpas Total <br /> Asesmen Nasional selama 1 bulan
                            </div>
                        </div>
                        {/* 6 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Merchandise Menarik
                            </div>
                        </div>
                        {/* Button */}
                        <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                            <button className="mx-auto d-block btnAppEureka mt-5 py-2 text-orange fw-bold">Pilih Paket</button>
                        </a>
                    </div>
                    {/* Super Pack */}
                    <div className="super-pack mx-5">
                        <h6 className="fw-bold fs-22 lh-160 text-center mb-4 text-white super-pack-padding">Super Pack</h6>
                        {/* Image */}
                        <img src={ Recommend } alt='Eureka' className="my-4" />
                        <div className="super-pack-padding fw-bold line-through fs-16 lh-160 dark-text">Rp 1.200.000</div>
                        <h5 className="super-pack-padding text-orange fw-bold d-inline-block fs-30 lh-160 mb-4">Rp 800.000</h5>
                        <small className="red-text fw-bold fs-16 lh-160"> / 12 bulan</small>
                        <h5 className="super-pack-padding fw-bold fs-16 lh-160 dark-text">Yang Didapatkan :</h5>
                        {/* 1 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses semua materi tanpa batas
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Latihan soal dan pembahasan gratis
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Asah skillmu di kuis harian sebebasnya
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses simulasi ujian 12 Bulan
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses webinar Kumpas Total Asesmen <br /> Nasional selama 12 bulan
                            </div>
                        </div>
                        {/* 6 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Gratis 15% buku panduan Asesmen Nasional
                            </div>
                        </div>
                        {/* 7 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Gratis konsultasi 24 pertanyaan bersama tutor
                            </div>
                        </div>
                        {/* 8 */}
                        <div className="super-pack-padding row mt-2">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Merchandise Menarik
                            </div>
                        </div>
                        {/* Button */}
                        <div className="super-pack-padding">
                            <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                                <button className="mx-auto d-block btnAppEurekaFill mt-5 py-2 text-white fw-bold">Pilih Paket</button>
                            </a>
                        </div>
                    </div>
                    {/* Basic Pack */}
                    <div className="basic-pack">
                        <h6 className="fw-bold fs-22 lh-160 dark-text text-center mb-4">Basic Pack</h6>
                        <div className="fw-bold line-through fs-16 lh-160 dark-text">Rp 443.000</div>
                        <h5 className="text-orange fw-bold d-inline-block fs-30 lh-160 mb-4">Rp 399.000</h5>
                        <small className="dark-text fw-bold fs-16 lh-160"> / 6 bulan</small>
                        <h5 className="fw-bold fs-16 lh-160 dark-text">Yang Didapatkan :</h5>
                        {/* 1 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses semua materi tanpa batas
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Latihan soal dan pembahasan gratis
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Asah skillmu di kuis harian sebebasnya
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses simulasi ujian 6 Bulan
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Akses webinar Kumpas Total <br /> Asesmen Nasional selama 6 bulan
                            </div>
                        </div>
                        {/* 6 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Merchandise Menarik
                            </div>
                        </div>
                        {/* 7 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 text-grey fw-500 fs-14 lh-160">
                                Gratis 10% buku panduan Asesmen Nasional
                            </div>
                        </div>
                        {/* Button */}
                        <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                            <button className="mx-auto d-block btnAppEureka mt-4 py-3 text-orange fw-bold">Pilih Paket</button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Content3