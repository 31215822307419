import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/styles.css'
import EurekaLogo from '../../assets/images/LogoEureka.png'
import ProductApp from '../../assets/images/ProductApp.svg'
import ProductBook from '../../assets/images/ProductBook.svg'
import { BsCaretDownFill } from "react-icons/bs";
// import { BsCaretUpFill } from "react-icons/bs";
// import { useState } from 'react';

const Header = () => {
  // const [ arrow, setArrow ] = useState(false)
  
  // const changeArrow = () => {
  //   setArrow(true)
  // }


  return (
    <Navbar className='ms-2 ms-lg-0 ms-md-0' expand="lg">
      {/* hidden only on xs */}
      <Navbar.Brand className='d-none d-sm-block' as={ Link } to="/"> 
          <img src={EurekaLogo} alt='Eureka' className='logo'></img>
      </Navbar.Brand>
      {/*  */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      {/* visible only on xs */}
      <Navbar.Brand className='d-block d-sm-none' as={ Link } to="/">
          <img src={EurekaLogo} alt='Eureka' className='logo'></img>
      </Navbar.Brand>
      {/*  */}
      <a id='btn-app' href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" className="btn d-block d-sm-none" role="button" aria-pressed="true" target="_blank" rel="noreferrer">Download Aplikasi</a>
      {/*  */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {/* Home */}
          <NavLink to='/' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>Beranda</NavLink>
          {/* About */}
          <NavLink to='/about' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>Tentang</NavLink>
          {/* Dropdown */}
          <NavDropdown
          // onClick={changeArrow}
          title={<div className='m-fw-bold m-fs-14' style={{"display": "inline-block"}}>Produk <BsCaretDownFill /></div>}
          // title={arrow ? <div style={{display: "inline-block"}}>Produk <BsCaretUpFill /></div> : <div style={{display: "inline-block"}}>Produk <BsCaretDownFill /></div>}
          id='link'
          style={{"whiteSpace": "nowrap"}}>
            {/* App */}
            <div id='dropdown-item'>
              <NavLink to='/product/android-app' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-3 col-3 ms-0 ms-lg-2 ms-md-2">
                      <img src={ProductApp} alt='Eureka' className='logoProduct'></img>
                    </div>
                    <div className="col-lg-5 col-5">
                      <h5 className='fw-bold lh-160 fs-14'>Aplikasi Eureka Edutech</h5>
                      <p className='fs-12 lh-160 fw-500'>Eureka Edutech membangun sebuah <br /> aplikasi pembelajaran yang....</p>
                      <u className='color-blue fw-bold lh-160 fw-600 fs-12'>Lihat Selengkapnya</u>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <hr />
            {/* Product */}
            <div>
              <NavLink to='/product/book' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-3 col-3 ms-0 ms-lg-2 ms-md-2">
                      <img src={ProductBook} alt='Eureka' className='logoProduct'></img>
                    </div>
                    <div className="col-lg-5 col-5">
                      <h5 className='fw-bold lh-160 fs-14'>Buku Panduan Asesmen Nasional</h5>
                      <p className='fs-12 lh-160 fw-500'>Eureka Edutech membangun sebuah <br /> aplikasi pembelajaran yang....</p>
                      <u className='color-blue fw-bold lh-160 fw-600 fs-12'>Lihat Selengkapnya</u>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </NavDropdown>
          {/* Blog */}
          <a id='link' className='mt-0 mt-lg-2 mt-md-2' href="https://medium.com/eureka-edutech" target="_blank" rel="noreferrer">Blog</a>
          <a id='btn-app' href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" className="btn d-none d-sm-block" role="button" aria-pressed="true" target="_blank" rel="noreferrer">Download Aplikasi</a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header;