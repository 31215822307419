// import { useState, useEffect } from 'react';
import { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Banner from './Banner'
import Content1 from './Content-1';
import Content2 from './Content-2';
import Content3 from './Content-3';
import Content4 from './Content-4';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // const [ num, setNum ] = useState(0) // 0 = default value
  // const [ car, setCar ] = useState(['Audi', 'BMW'])

  // const addNum = () => {  // function
  //   setNum(num+1)
  // }

  // const substract = () => {  // function to send params
  //   setNum(num - 1)
  // }

  // useEffect(() => {   // mounted
  //   console.log('render')
  // }, [])

  // useEffect(() => {   // update salah satu state berubah
  //   console.log('render angka berubah')
  // }, [num])

  return (
    <div className='overflow-x-hidden'>
      <Header />

      <Banner />
      <Content1 />
      <Content2 />
      <Content3 />
      <Content4 />

      {/* <hr />{`Number = ${num}`} <br />
      <button onClick={addNum} className='btn btn-success'>Plus</button>
      <button onClick={() => substract()} className='btn btn-danger m-2'>Minus</button>
      <hr />{JSON.stringify(car)} */}
      <br />
      <br />

      <Footer />
    </div>
  )
}

export default Home;