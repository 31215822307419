const Content3 = () => {
    return(
        <div className="content3Policy mt-4">
            <div className="fs-16 m-fs-12 lh-160 fw-500 text-grey">
                <b className="m-fs-12">Eureka Edutech </b>berkomitmen untuk menghormati dan melindungi privasi Anda. Kami memahami betapa pentingnya data pribadi Anda dan kami tidak akan membagikannya kepada orang-orang atau pihak ketiga lainnya tanpa izin Anda. Silahkan baca Kebijakan Privasi berikut untuk mempelajari lebih lanjut tentang bagaimana kami akan menjunjung tinggi janji kami kepada Anda.
                <br />
                <br />
                Kebijakan ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan mengungkapkan data pribadi Anda yang kami peroleh saat Anda menggunakan Eureka Edutech. Dengan terus menggunakan atau mengakses aplikasi kami, itu berarti Anda telah menerima praktik-praktik yang diuraikan dalam Kebijakan ini.
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Informasi yang Kami Akses dan Kumpulkan</b> 
                <br />
                <br />
                <b className="m-fs-12">Informasi Akun: </b>Dengan mendaftarkan diri pada aplikasi di Eureka Edutech, Anda sudah memberikan nomor ponsel. Kemudian, Anda telah memberi kami otorisasi untuk mengakses panggilan telepon Anda agar Anda dapat memverifikasi akun dan melakukan perubahan kata sandi apabila Anda lupa terhadap kata sandi Anda melalui panggilan singkat untuk mendapatkan sebuah OTP (One Time Password). Informasi lainnya seperti nama, email, nomor telepon, provinsi, kota, dan alamat sekolah juga dapat dilihat dan disimpan oleh kami.
                <br />
                <br />
                <b className="m-fs-12">Data dari Pengaturan Perangkat Seluler: </b>Kami memperoleh informasi yang Anda izinkan kami terima seperti akses panggilan telepon dan lokasi Anda untuk menyediakan fitur layanan kami.
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Informasi yang Secara Otomatis Terkumpul</b>
                <br />
                <br />
                <b className="m-fs-12">Informasi Penggunaan dan Log: </b>Kami mengumpulkan informasi terkait layanan, diagnostik, dan kinerja. Ini termasuk informasi tentang aktivitas Anda (seperti bagaimana Anda menggunakan layanan aplikasi kami, bagaimana Anda menggunakan layanan website kami, dan lain-lain), berkas log, laporan diagnostik, data crash, website, serta laporan kinerja.
                <br />
                <br />
                <b className="m-fs-12">Informasi Perangkat Seluler dan Koneksi: </b>Kami mengumpulkan informasi khusus mengenai perangkat Anda ketika menginstal, mengakses, atau menggunakan layanan kami. Ini termasuk informasi seperti model perangkat keras, sistem operasi, informasi browser, alamat IP, informasi jaringan seluler termasuk nomor ponsel, dan pengidentifikasi perangkat.
                <br />
                <br />
                <b className="m-fs-12">Cookie: </b>Kami menggunakan cookie untuk beroperasi, termasuk untuk menyediakan layanan kami yang berbasis web. Kami juga menggunakannya untuk meningkatkan pengalaman Anda dengan memahami bagaimana layanan kami digunakan, dan menyesuaikan layanan kami agar sesuai dengan Anda. Kami dapat menggunakan cookie dan teknologi serupa di situs web kami untuk mengumpulkan informasi seperti tanggal dan waktu kunjungan, lokasi geografis, halaman yang dilihat, alamat IP, tautan ke atau dari halaman manapun, jenis dan versi browser, sistem operasi, sumber rujukan, dan waktu yang dihabiskan di situs web kami.
                <br />
                <br />
                <b className="m-fs-12">Informasi Status: </b>Kami mengumpulkan informasi tentang perubahan bagian profil Anda, seperti apakah Anda telah mengubah informasi profil Anda pada fitur edit profile.
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Informasi Pihak Ketiga</b>
                <br />
                <br />
                <b className="m-fs-12">Penyedia Pihak Ketiga: </b>Kami bekerja dengan penyedia pihak ketiga untuk menyediakan layanan atas nama kami, untuk meningkatkan layanan atau untuk membantu kami dalam menganalisis bagaimana layanan kami digunakan. Kami dapat menerima informasi dari penyedia pihak ketiga tergantung pada kebijakan dan pengaturan akun Anda dengan pihak ketiga yang berlaku. Kami tidak memiliki kendali dan tidak bertanggung jawab atas penggunaan atau pengungkapan informasi pribadi Anda oleh pihak ketiga.
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Data Pengguna</b>
                <br />
                <br />
                Kami menyimpan informasi di server yang berlokasi di Indonesia. Kami bekerja dengan pihak ketiga untuk menyediakan berbagai layanan terkait teknologi. Data pengerjaan soal Anda tidak akan dibagikan dengan perusahaan atau layanan lain manapun. Data Anda secara aman disimpan dan tidak digunakan untuk kepentingan apapun selain pengembangan aplikasi dan layanan kami.
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Kerahasiaan Informasi dan Layanan Konsumen</b>
                <ul>
                    <li className="my-4 m-fs-12">Eureka Edutech tidak akan memberikan atau mendistribusikan informasi pribadi para pengguna yang didapat melalui penggunaan aplikasi kepada pihak ketiga. Akan tetapi, hal ini tidak termasuk ketika informasi tersebut diminta oleh pihak berwenang terkait, antara lain: Kepolisian Negara Republik Indonesia, Pejabat Pegawai Negeri Sipil tertentu dan Kejaksaan Standards of Commision mengajukan permohonan informasi, atau dalam hal lain melalui prosedur hukum.</li>
                    <li className="my-4 m-fs-12">Eureka Edutech harus menanggapi secara cepat seluruh keluhan para pengguna terhadap layanan. Bila penjelasan singkat mengenai keluhan tersebut tidak mencukupi, Eureka Edutech akan memposting atau menampilkan penjelasan di layar aplikasi atau melalui email dan akan merencanakan peningkatan sistem.</li>
                    <li className="mt-4 m-fs-12">Eureka Edutech bersandar pada Perundang-undangan terkait Intellectual Property Right (HAKI) dan data privasi pengguna.</li>
                </ul>
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Bagaimana Kami Menggunakan Informasi</b>
                <br />
                <br />
                Kami menggunakan semua informasi yang dapat membantu kami dalam memperbaiki hal-hal sebagai berikut:
                <ul>
                    <li className="my-4 m-fs-12">
                        <b className="m-fs-12">Layanan: </b>Kami mengoperasikan dan menyediakan layanan kami, termasuk customer service, dan juga meningkatkan, memperbaiki, dan menyesuaikan layanan kami. Dari kegiatan tersebut, kami menganalisis informasi yang ada untuk mengevaluasi dan meningkatkan layanan kami, meneliti, mengembangkan, dan menguji layanan serta fitur baru. Kami juga menggunakan informasi Anda untuk merespons Anda ketika Anda menghubungi kami.
                    </li>
                    <li className="mt-4 m-fs-12">
                        <b className="m-fs-12">Keselamatan dan Keamanan: </b>Kami memverifikasi akun dan aktivitas, dan mempromosikan keselamatan dan keamanan di dalam dan di luar layanan kami, seperti dengan menyelidiki aktivitas yang mencurigakan atau pelanggaran terhadap persyaratan kami, dan untuk memastikan bahwa layanan kami digunakan secara legal.
                    </li>
                </ul>
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Informasi yang Dibagikan</b>
                <br />
                <br />
                Anda membagikan informasi Anda saat Anda menggunakan Eureka Edutech untuk mengganti kata sandi dan mengerjakan soal, serta kami memberikan informasi Anda untuk membantu kami mengoperasikan, menyediakan, meningkatkan, memahami, menyesuaikan, mendukung, dan memasarkan layanan kami.
                <br />
                <br />
                <ul>
                    <li className="my-4 m-fs-12"><b className="m-fs-12">Informasi Akun: </b>Nama, nomor ponsel, email, provinsi, kota, nama sekolah, kelas, dan jurusan dapat tersedia bagi Anda ketika menggunakan layanan kami.</li>
                    <li className="mt-4 m-fs-12"><b className="m-fs-12">Penyedia Pihak Ketiga: </b>Kami bekerja dengan penyedia pihak ketiga untuk membantu kami mengoperasikan, menyediakan, meningkatkan, memahami, menyesuaikan, mendukung, dan memasarkan layanan kami. Saat kami membagikan informasi dengan penyedia pihak ketiga, kami meminta mereka untuk menggunakan informasi Anda sesuai dengan instruksi dan persyaratan kami atau seizin Anda.</li>
                </ul>
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Persyaratan Layanan</b>
                <br />
                <br />
                <b className="m-fs-12">A. Registrasi</b>
                <br />
                Anda harus mendaftar untuk menggunakan layanan kami menggunakan data yang akurat serta memberikan nomor ponsel Anda saat ini. Anda setuju untuk menerima panggilan singkat (dari kami atau penyedia pihak ketiga kami) dengan kode OTP untuk mendaftar atau login ke layanan kami.
                <br />
                <br />
                <b className="m-fs-12">B. Umur</b>
                <br />
                Usia minimal untuk menggunakan layanan kami adalah 15 tahun (dengan ketentuan usia 15 tahun hingga 18 tahun masih dalam pengawasan/bimbingan dan memiliki izin dari orang tua atau wali). Selain usia minimum yang diwajibkan untuk menggunakan layanan kami berdasarkan hukum yang berlaku, jika Anda belum cukup umur untuk memiliki wewenang untuk menyetujui persyaratan kami di negara Anda, orang tua atau wali Anda harus menyetujui persyaratan kami atas nama Anda. Dengan menyetujui Kebijakan Privasi, Anda dan/atau orang tua, wali atau pengampu Anda (dalam hal Anda berusia di bawah 18 (delapan belas) tahun) mengakui bahwa Anda telah membaca dan memahami Kebijakan Privasi ini dan menyetujui segala ketentuannya.
                <br />
                <br />
                <b className="m-fs-12">C. Perangkat Seluler dan Perangkat Lunak</b>
                <br />
                Anda harus menyediakan perangkat seluler, perangkat lunak, dan koneksi data tertentu untuk menggunakan layanan kami, yang tidak kami sediakan. Selama Anda menggunakan layanan kami, Anda menyetujui untuk mengunduh dan menginstal pembaruan ke layanan kami, secara otomatis.
                <br />
                <br />
                <b className="m-fs-12">D. Biaya dan Pajak</b>
                <br />
                Anda bertanggung jawab atas semua paket data operator dan biaya serta pajak lain yang terkait dengan penggunaan layanan kami oleh Anda.
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Mengelola Informasi Anda</b>
                <br />
                <br />
                Jika Anda ingin mengelola atau mengubah informasi seperti nomor ponsel dan email Anda, terdapat beberapa cara yang memungkinkan Anda untuk melakukannya, yaitu sebagai berikut:
                <ul>
                    <li className="my-4 m-fs-12">Mengubah nomor ponsel Anda dengan menghubungi kami lewat email ke info@eurekaedutech.com</li>
                    <li className="mt-4 m-fs-12">Anda juga dapat mengubah nama, provinsi, kota, nama sekolah, kelas, dan jurusan Anda kapan saja melalui aplikasi.</li>
                </ul>
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Perubahan Kebijakan Privasi Kami</b>
                <br />
                <br />
                Kami dapat mengubah atau merevisi Kebijakan Privasi. Setiap perubahan pada Kebijakan Privasi ini akan menjadi efektif ketika kami mengumumkan versi revisi melalui situs https://eureka.my.id/kebijakan-privasi. Kami sangat menganjurkan Anda untuk mengakses situs kami secara berkala agar mengetahui perubahan-perubahan terbaru. Dengan terus mengakses dan menggunakan layanan Eureka Edutech, Anda dianggap telah menerima dan menyetujui segala perubahan.
                <br />
                <br />
                <br />
                <b className="fs-20 m-fs-14">Kontak Kami</b>
                <br />
                <br />
                Jika Anda memiliki keraguan atau pertanyaan tentang Kebijakan Privasi ini, Anda dapat menghubungi kami di info@eurekaedutech.com. Jika Anda tidak setuju atau tidak dapat menerima praktik apapun yang diuraikan dalam Kebijakan Privasi ini, mohon disampaikan agar kami dapat meningkatkan kualitas layanan.
            </div>
        </div>
    )
}

export default Content3