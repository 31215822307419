import GooglePlay from '../../../assets/images/GooglePlay.svg'

const Content4 = () => {
    return (
        <div className="content4ProductApp mx-auto d-block">
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-7 mt-lg-5 mt-md-5 textBannerProductApp">
                    <h5 className="mt-3 white-text fw-bold fs-30 m-fs-12 lh-160">Yuk download aplikasinya sekarang, <br /> GRATIS!</h5>
                    <br /> <br />
                    <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                        <object className="logoGooglePlayProductApp pointer-events" type="image/svg+xml" aria-label="logo" data={ GooglePlay }></object>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Content4