import GooglePlay from '../../../assets/images/GooglePlay.svg'

const Content1 = () => {
    return (
        <div className="produkApp">
            <h6 className="fs-30 m-fs-16 fw-bold lh-160 color-blue text-center">Kenapa pilih Eureka Edutech App ?</h6>
            <p className='text-grey m-fs-12'>Eureka Edutech membangun sebuah aplikasi pembelajaran yang dapat membantu siswa belajar dan melakukan simulasi asesmen nasional secara real-time dengan bank soal yang sudah diramu sedemikian rupa. Salah satu fitur unggulan dari aplikasi ini adalah siswa dapat mempelajari materi literasi dan numerasi secara langsung yang dilengkapi dengan contoh soal dan pembahasan, serta peserta dapat melakukan simulasi ujian sesuai topik yang dipilih. Selain itu, ada fitur daily question untuk mengasah daya ingat siswa setiap harinya dan fitur activities untuk merefresh kembali aktivitas yang telah dilakukan sebelumnya. Udah gak sabar berpetualang diaplikasi kita? Yuk download aplikasinya sekarang, GRATIS!</p>
            <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                <object className="pointer-events mx-auto d-block" type="image/svg+xml" aria-label="logo" data={ GooglePlay }></object>
            </a>
        </div>
    )
}

export default Content1