import axios from "axios"
import { useState } from "react"

const Content2 = () => {
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    })

    const handle = (e) => {
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
    }

    const submit = (e) => {
        e.preventDefault()
        axios.post('https://api.eurekaedutech.com/v1/web-message',{
            name: data.name,
            email: data.email,
            phone: data.phone,
            subject: data.subject,
            message: data.message
        }).then(() => {
            window.location.reload()
        }).catch(() => {})
    }


    return(
        <div className="content2Contact mt-5 mt-lg-0 mt-md-0">
            <h6 className="color-blue lh-160 fs-22 m-fs-16 fw-bold">Hubungi Kami</h6>
            <p className="text-grey fw-500 fs-16 m-fs-12 lh-160">Isi form berikut jika Anda memiliki pertanyaan, keluhan, pengajuan kerja sama / sponsorship. Kami akan menghubungi Anda kembali secepatnya.</p>
            <form onSubmit={(e) => submit(e)} className="form">
                <h6>Semua bagian wajib diisi.</h6>
                {/*  */}
                <h6 className="mt-4">Nama</h6>
                <input required onChange={(e) => handle(e)} value={data.name} id="name" className="mt-1 mb-4" type="text" />
                {/*  */}
                <h6>Alamat Email</h6>
                <input required onChange={(e) => handle(e)} value={data.email} id="email" className="mt-1 mb-4" type="text" />
                {/*  */}
                <h6>Nomor Handphone</h6>
                <input required onChange={(e) => handle(e)} value={data.phone} id="phone" className="mt-1 mb-4" type="text" />
                {/*  */}
                <h6>Subjek</h6>
                <input required onChange={(e) => handle(e)} value={data.subject} id="subject" className="mt-1 mb-4" type="text" />
                {/*  */}
                <h6>Isi Pesan</h6>
                <textarea required onChange={(e) => handle(e)} value={data.message} name="" id="message" cols="30" rows="10"></textarea>
                <button className="btn">Kirim Pesan</button>
            </form>
        </div>
    )
}

export default Content2