import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home'
import About from '../pages/About'
import AndroidApp from '../pages/Product/AndroidApp'
import Book from '../pages/Product/Book'
import Contact from '../pages/Contact'
import Terms from '../pages/Terms'
import Policy from '../pages/Policy'

const router = () => {
  return(
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/product/android-app" element={<AndroidApp />} />
      <Route exact path="/product/book" element={<Book />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/policy" element={<Policy />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default router