import { Carousel } from 'react-bootstrap'
import App1 from '../../../assets/images/App1.svg'
import App2 from '../../../assets/images/App2.svg'
import App3 from '../../../assets/images/App3.svg'
import App4 from '../../../assets/images/App4.svg'
import App5 from '../../../assets/images/App5.svg'
import App6 from '../../../assets/images/App6.svg'

const Content2Mobile = () => {
    return (
        <div className="produkApp2">
            <h6 className="fw-bold fs-30 m-fs-16 lh-160 color-blue text-center text-lg-start text-md-start">Fitur Belajar Apa yang <br className='d-block d-sm-none' /> Bisa Kamu Gunakan ?</h6>
            <Carousel
                id='app'
                className='mt-3'
                nextIcon={null}
                prevIcon={null}
                interval={null}
                style={{"height": "385px"}}
                >
                    {/* 1 */}
                    <Carousel.Item>
                        <div className='d-flex justify-content-center'>
                            <div className='imageApp'>
                                <img src={ App1 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-12 lh-160 fw-bold dark-text nowrap'>Ribuan Soal Literasi dan Numerasi</div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 2 */}
                    <Carousel.Item>
                        <div className='d-flex justify-content-center'>
                            <div className='imageApp'>
                                <img src={ App2 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-12 lh-160 fw-bold dark-text nowrap'>Uji Pemahaman Kamu Biar Makin Percaya Diri</div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 3 */}
                    <Carousel.Item>
                        <div className='d-flex justify-content-center'>
                            <div className='imageApp'>
                                <img src={ App3 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-12 lh-160 fw-bold dark-text nowrap'>Latihan Soal yang Nggak Bikin Ribet</div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 4 */}
                    <Carousel.Item>
                        <div className='d-flex justify-content-center'>
                            <div className='imageApp'>
                                <img src={ App4 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-12 lh-160 fw-bold dark-text'>Progres Kamu Akan Selalu Disimpan</div> <br />
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 5 */}
                    <Carousel.Item>
                        <div className='d-flex justify-content-center'>
                            <div className='imageApp'>
                                <img src={ App5 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-12 lh-160 fw-bold dark-text nowrap'>Beragam Artikel Menarik Buat Menambah Wawasan</div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 6 */}
                    <Carousel.Item>
                        <div className='d-flex justify-content-center'>
                            <div className='imageApp'>
                                <img src={ App6 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-12 lh-160 fw-bold dark-text nowrap'>Simpan Materi Favorit Kamu</div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
        </div>
    )
}

export default Content2Mobile