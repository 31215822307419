import { BsCheckCircleFill } from "react-icons/bs";
import RecommendMobile from '../../../assets/images/Recommend-mobile.svg'

const Content3Mobile = () => {
    return(
        <div>
            <h6 className="text-center color-blue fw-bold m-fs-16 lh-160 my-5">Langganan Eureka Edutech dan <br /> Nikmati Benefitnya</h6>
            <div id="hide-scrollbar" className="d-flex nowrap pb-5" style={{"overflow": "auto", "width": "100%"}}>
                {/* Starter Pack */}
                <div className="starter-pack">
                    <h6 className="fw-bold fs-12 lh-160 dark-text text-center mb-3">Starter Pack</h6>
                    <div className="fw-bold line-through m-fs-10 lh-160 dark-text">Rp 120.000</div>
                    <h5 className="text-orange fw-bold d-inline-block fs-20 lh-160 mb-3">Rp 95.000</h5>
                    <small className="dark-text fw-bold m-fs-10 lh-160 me-5"> / 1 bulan</small>
                    <h5 className="fw-bold m-fs-10 lh-160 dark-text">Yang Didapatkan :</h5>
                    {/* 1 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses semua materi tanpa batas
                        </div>
                    </div>
                    {/* 2 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Latihan soal dan pembahasan gratis
                        </div>
                    </div>
                    {/* 3 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Asah skillmu di kuis harian sebebasnya
                        </div>
                    </div>
                    {/* 4 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses simulasi ujian 1 Bulan
                        </div>
                    </div>
                    {/* 5 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses webinar Kumpas Total Asesmen Nasional selama 1 bulan
                        </div>
                    </div>
                    {/* 6 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Merchandise Menarik
                        </div>
                    </div>
                    <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                        <button className="mx-auto d-block btnAppEureka mt-3 py-1 text-orange fw-bold m-fs-12">Pilih Paket</button>
                    </a>
                </div>
                {/* Super Pack */}
                <div className="super-pack">
                    <h6 className="fw-bold m-fs-12 lh-160 text-center mb-3 mt-2 text-white super-pack-padding">Super Pack</h6>
                    {/* Image */}
                    <img src={ RecommendMobile } alt='Eureka' className="mt-2 mb-3" />
                    <div className="super-pack-padding fw-bold line-through m-fs-10 lh-160 dark-text">Rp 1.200.000</div>
                    <h5 className="super-pack-padding text-orange fw-bold d-inline-block m-fs-20 lh-160 mb-3">Rp 800.000</h5>
                    <small className="red-text fw-bold m-fs-10 lh-160 me-5">/ 12 bulan</small>
                    <h5 className="super-pack-padding fw-bold m-fs-10 lh-160 dark-text">Yang Didapatkan :</h5>
                    {/* 1 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses semua materi tanpa batas
                        </div>
                    </div>
                    {/* 2 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Latihan soal dan pembahasan gratis
                        </div>
                    </div>
                    {/* 3 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Asah skillmu di kuis harian sebebasnya
                        </div>
                    </div>
                    {/* 4 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses simulasi ujian 12 Bulan
                        </div>
                    </div>
                    {/* 5 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses webinar Kumpas Total Asesmen Nasional selama 12 bulan
                        </div>
                    </div>
                    {/* 6 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Gratis 15% buku panduan Asesmen Nasional
                        </div>
                    </div>
                    {/* 7 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Gratis konsultasi 24 pertanyaan bersama tutor
                        </div>
                    </div>
                    {/* 8 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Merchandise Menarik
                        </div>
                    </div>
                    {/* Button */}
                    <div className="super-pack-padding">
                        <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                            <button className="mx-auto d-block btnAppEurekaFill mt-3 py-1 text-white fw-bold m-fs-12">Pilih Paket</button>
                        </a>
                    </div>
                </div>
                {/* Basic Pack */}
                <div className="basic-pack">
                    <h6 className="fw-bold m-fs-12 lh-160 dark-text text-center mb-3">Basic Pack</h6>
                    <div className="fw-bold line-through m-fs-10 lh-160 dark-text">Rp 443.000</div>
                    <h5 className="text-orange fw-bold d-inline-block fs-20 lh-160 mb-3">Rp 399.000</h5>
                    <small className="dark-text fw-bold m-fs-10 lh-160 me-5"> / 6 bulan</small>
                    <h5 className="fw-bold m-fs-10 lh-160 dark-text">Yang Didapatkan :</h5>
                    {/* 1 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses semua materi tanpa batas
                        </div>
                    </div>
                    {/* 2 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Latihan soal dan pembahasan gratis
                        </div>
                    </div>
                    {/* 3 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Asah skillmu di kuis harian sebebasnya
                        </div>
                    </div>
                    {/* 4 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses simulasi ujian 6 Bulan
                        </div>
                    </div>
                    {/* 5 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Akses webinar Kumpas Total <br /> Asesmen Nasional selama 6 bulan
                        </div>
                    </div>
                    {/* 6 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Merchandise Menarik
                        </div>
                    </div>
                    {/* 7 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            Gratis 10% buku panduan Asesmen Nasional
                        </div>
                    </div>
                    {/* Button */}
                    <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                        <button className="mx-auto d-block btnAppEureka mt-3 py-2 text-orange fw-bold m-fs-12">Pilih Paket</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Content3Mobile