import './App.css';

import { BrowserRouter } from "react-router-dom";
import Router from './routes/index'

function App() {
  return (
    <div className='App'>
      <BrowserRouter>{/* 41.20 */}
        <Router/>
      </BrowserRouter>
    </div>
  );
}

export default App;
