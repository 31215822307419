import IconSiap from '../../assets/images/IconSiap.svg'
import IconFokus from '../../assets/images/IconFokus.svg'
import IconMantap from '../../assets/images/IconMantap.svg'
import IconPaham from '../../assets/images/IconPaham.svg'

const Content1 = () => {
    return (
        <div className="content1 vw-100">
            <h6 className='m-fs-16 fs-30 color-blue fw-bold text-center'>Persiapan Asesmen Nasional jadi mudah <br /> Bersama Eureka Edutech</h6>
            {/*  */}
            <div id="row-content1" className='d-flex justify-content-center'>
                {/*  */}
                <div className="row">
                    {/* Card 1 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 ms-4 me-1" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconSiap }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">Lebih Siap</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">Siap menghadapi Asesmen Nasional dengan contoh soal di dalam materi, latihan soal hingga ujian.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 margin-icons" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconMantap }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">Lebih Mantap</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">Jadi bagian dari peningkatan nilai PISA di Indonesia dengan kesiapan untuk menghadapi Asesmen Nasional.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className='d-flex justify-content-center'>
                {/*  */}
                <div className="row">
                    {/* Card 1 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 ms-4 me-1" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconFokus }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">Lebih Fokus</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">Fokus persiapan dengan memahami konsep literasi dan numerasi dalam Asesmen Nasional sesuai dengan indikator Pusmenjar.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 margin-icons" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconPaham }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">Lebih Paham</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">Tidak hanya latihan soal, ini waktunya memahami konsep pelajaran dengan Materi Literasi dan Numerasi yang sangat lengkap.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content1