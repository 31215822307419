import { Carousel } from 'react-bootstrap'
import ImageContent1 from '../../assets/images/Content3.png'
import ImageContent2 from '../../assets/images/Content3-2.png'
import ImageContent3 from '../../assets/images/Content3-3.png'
import ImageContent4 from '../../assets/images/Content3-4.png'
import ImageContent5 from '../../assets/images/Content3-5.png'

const Content3Mobile = () => {
    return(
        <div>
            <h6 className="fw-bold m-fs-16 color-blue text-center">Yuk Mulai Pakai Eureka Edutech !</h6>
            <Carousel
                id='home' 
                nextIcon={null}
                prevIcon={null}
                interval={null}
                style={{"height": "500px"}}>
                    {/* 1 */}
                    <Carousel.Item>
                        <div className='text-center my-5'>
                            <img className='img-content3' src={ ImageContent1 } alt="Eureka" /> <br /> <br />
                            <div id='rounded-on' className="m-fs-12 lh-160 ms-4 fw-bold d-inline-block">1</div>
                            <p className='d-inline-block m-fs-12 lh-160 fw-bold dark-text'>Download Aplikasi "Eureka Edutech" di Playstore</p>
                        </div>
                    </Carousel.Item>
                    {/* 2 */}
                    <Carousel.Item>
                        <div className='text-center my-5'>
                            <img className='img-content3' src={ ImageContent2 } alt="Eureka" /> <br /> <br />
                            <div id='rounded-on' className="m-fs-12 lh-160 ms-4 fw-bold d-inline-block">2</div>
                            <p className='d-inline-block m-fs-12 lh-160 fw-bold dark-text'>Lakukan Login atau Registrasi <br /> bagi yang belum memiliki akun</p>
                        </div>
                    </Carousel.Item>
                    {/* 3 */}
                    <Carousel.Item>
                        <div className='text-center my-5'>
                            <img className='img-content3' src={ ImageContent3 } alt="Eureka" /> <br /> <br />
                            <div id='rounded-on' className="m-fs-12 lh-160 ms-4 fw-bold d-inline-block">3</div>
                            <p className='d-inline-block m-fs-12 lh-160 fw-bold dark-text'>Pilih Materi Pembelajaran yang Kamu Inginkan</p>
                        </div>
                    </Carousel.Item>
                    {/* 4 */}
                    <Carousel.Item>
                        <div className='text-center my-5'>
                            <img className='img-content3' src={ ImageContent4 } alt="Eureka" /> <br /> <br />
                            <div id='rounded-on' className="m-fs-12 lh-160 ms-4 fw-bold d-inline-block">4</div>
                            <p className='d-inline-block m-fs-12 lh-160 fw-bold dark-text'>Ada Materi, Latihan Soal dan Ujian <br /> pada tiap Topik Pembelajaran</p>
                        </div>
                    </Carousel.Item>
                    {/* 5 */}
                    <Carousel.Item>
                        <div className='text-center my-5'>
                            <img className='img-content3' src={ ImageContent5 } alt="Eureka" /> <br /> <br />
                            <div id='rounded-on' className="m-fs-12 lh-160 ms-4 fw-bold d-inline-block">5</div>
                            <p className='d-inline-block m-fs-12 lh-160 fw-bold dark-text'>Terdapat kuis harian yang dapat kamu kerjakan <br /> setiap hari, juga lho !</p>
                        </div>
                    </Carousel.Item>
                </Carousel>
        </div>
    )
}

export default Content3Mobile