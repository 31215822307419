import { Carousel } from 'react-bootstrap'
import Herman from '../../assets/images/members/Herman.svg'
import Mahendra from '../../assets/images/members/Mahendra.svg'
import Herry from '../../assets/images/members/Herry.svg'
import Hendra from '../../assets/images/members/Hendra.svg'
import Jong from '../../assets/images/members/Jong.svg'
import Zainul from '../../assets/images/members/Zainul.svg'
import Yophiandi from '../../assets/images/members/Yophiandi.svg'
import Elvita from '../../assets/images/members/Elvita.svg'
import Iftihal from '../../assets/images/members/Iftihal.svg'
import Cahyarani from '../../assets/images/members/Cahyarani.svg'
import Dimas from '../../assets/images/members/Dimas.svg'
import Hadistian from '../../assets/images/members/Hadistian.svg'
import Daniel from '../../assets/images/members/Daniel.svg'
import Fadella from '../../assets/images/members/Fadella.svg'
import Ikrima from '../../assets/images/members/Ikrima.svg'
import Fatur from '../../assets/images/members/Fatur.svg'
import Putri from '../../assets/images/members/Putri.svg'
import Ihsan from '../../assets/images/members/Ihsan.svg'

import BtnCarousel from '../../assets/images/Carousel-btn.svg'


const TeamMobile = () => {
    const directionButtons = (direction) => {
        return (
          <span
            aria-hidden="true"
            className={direction === "Next" ? "button-next" : "button-prev"}
          >
            <img className='mt-4' src={ BtnCarousel } alt="next" style={{"width": "20px"}} />
          </span>
        )
    }

    return(
        <div id="team" className='mb-4 mt-5'>
            <div id="text-team" className="text-center lh-160 fs-16 fw-bold color-blue">Tim Kami</div> 
            <Carousel 
            nextLabel={"Next"}
            prevLabel={"Previous"}
            nextIcon={directionButtons("Next")}
            prevIcon={directionButtons("Previous")}
            interval={null}
            indicators={false}>
                {/* 1 */}
                <Carousel.Item>
                    <div className='d-flex flex-row justify-content-center mt-5'>
                        {/* Card 1 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Herman } className="mx-auto d-block" alt="Herman Huang" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Herman Huang, M.Sc., MBA</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Founder</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar Master of Business di IMD Business School, Swiss. Berpengalaman sebagai technopreneur selama 16 tahun di beberapa sektor bisnis dan berkontribusi dalam mengembangkan kemampuan wirausaha muda.</p>
                            </div>
                        </div>
                        {/* Card 2 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Mahendra } className="mx-auto d-block" alt="Mahendra" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>AC Mahendra K Datu, Ph.D</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>CEO & Literacy Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar Ph.D di The National University of Singapore (NUS), SEAS Trade and Strategic Industry. Berpengalaman sebagai mentor di kampus dan strategi perusahaan. Desainer OSCI dan kolumnis inovasi di media besar. Beliau juga aktif sebagai penulis buku sejak 2009 tentang bisnis dan manajemen, ekonomi, keuangan, dan produktivitas sumber daya manusia.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                {/* 2 */}
                <Carousel.Item>
                    <div className='d-flex flex-row justify-content-center mt-5'>
                        {/* Card 1 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Herry } className="mx-auto d-block mt-4" alt="Herry" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Herry Kwee, Ph.D</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Director & Numeration Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar Ph.D di bidang Fisika dari College of William and Mary. Berpengalaman lebih dari 10 tahun di bidang pendidikan. Aktif sebagai pembina tim olimpiade fisika Indonesia sejak tahun 2000 serta tim akademik dan juri pelatihan/kompetisi olimpiade tingkat internasional dan nasional.</p>
                            </div>
                        </div>
                        {/* Card 2 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Hendra } className="mx-auto d-block mt-4" alt="Hendra" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Hendra Kwee, Ph.D</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Director & Numeration Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar Ph.D di bidang Fisika dari College of William and Mary. Berpengalaman 19 tahun di bidang pendidikan. Aktif sebagai pemimpin tim olimpiade fisika Indonesia dan olimpiade lainnya dan telah meraih medali di setiap kompetisi yang diikuti oleh para peserta pelatihan.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                {/* 3 */}
                <Carousel.Item>
                    <div className='d-flex flex-row justify-content-center mt-5'>
                        {/* Card 1 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Jong } className="mx-auto d-block mt-4" alt="Jong" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Jong Anly Tan, Ph.D</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Numeration Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar Ph.D di bidang Fisika dari College of William and Mary. Berpengalaman 11 tahun sebagai leader, juri dan trainer di beberapa olimpiade fisika nasional dan internasional. Saat ini aktif sebagai pembina tim Olimpiade Fisika Indonesia sejak 2009.</p>
                            </div>
                        </div>
                        {/* Card 2 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Zainul } className="mx-auto d-block mt-4" alt="Zainul" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Zainul Abidin, Ph.D </h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Numeration Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Memperoleh gelar Ph.D dalam Fisika Teoretis dari College of William and Mary. Berpengalaman 9 tahun sebagai pelatih dan ketua tim Delegasi Indonesia di Olimpiade Fisika Asia. Prestasi yang diraih antara lain Medali Perak pada Olimpiade Fisika Asia ke-1 di Karawaci, Indonesia 2000 dan Honorable Mention pada Olimpiade Fisika Internasional ke-31 di Leicester, Inggris 2000.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                {/* 4 */}
                <Carousel.Item>
                    <div className='d-flex flex-row justify-content-center mt-5'>
                        {/* Card 1 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Yophiandi } className="mx-auto d-block mt-4" alt="Yophiandi" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Ign. Yophiandi Kurniawan M.Sos</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Literacy Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">20 tahun pengalaman dalam jurnalisme dan literasi. Pernah bekerja di beberapa portal berita terkemuka di Indonesia.</p>
                            </div>
                        </div>
                        {/* Card 2 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Elvita } className="mx-auto d-block mt-4" alt="Elvita" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Elvita Sari</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Project Leader</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar sarjana sains (S.Si) pada tahun 2018 dan telah berkecimpung selama 6 tahun di dunia pengembangan pendidikan. Selain itu juga berpengalaman di bidang marketing, penelitian, dan manajemen proyek di komunitas dan startup, khususnya pendidikan dan human development.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                {/* 5 */}
                <Carousel.Item>
                <div className='d-flex flex-row justify-content-center mt-5'>
                    {/* Card 1 */}
                    <div className="card mx-1">
                        <img id='team-img' src={ Iftihal } className="mx-auto d-block mt-4" alt="Iftihal" />
                        <div className="card-body text-center">
                            <h5 className='color-blue fw-bold m-fs-10'>Iftihal Muslim Rahman</h5>
                            <h6 className='color-blue m-fs-8 lh-10'>Marketing Executive</h6>
                            <p className="card-text text-grey lh-10 m-fs-8">Pengalaman 6 tahun di bidang jurnalistik. Seorang public speaker, penulis puisi dan fiksi, dan beauty content creator.</p>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="card mx-1">
                        <img id='team-img' src={ Cahyarani } className="mx-auto d-block mt-4" alt="Cahyarani" />
                        <div className="card-body text-center">
                            <h5 className='color-blue fw-bold m-fs-10'>Cahyarani Paramesti</h5>
                            <h6 className='color-blue m-fs-8 lh-10'>Marketing Executive</h6>
                            <p className="card-text text-grey lh-10 m-fs-8">Pengalaman 3 tahun sebagai pianis, pelukis, dan penari, kepala divisi artistik dan publikasi, desain grafis dan penulis konten.</p>
                        </div>
                    </div>
                </div>
                </Carousel.Item>
                {/* 6 */}
                <Carousel.Item>
                <div className='d-flex flex-row justify-content-center mt-5'>
                    {/* Card 1 */}
                    <div className="card mx-1">
                        <img id='team-img' src={ Fatur } className="mx-auto d-block mt-4" alt="Fatur" />
                        <div className="card-body text-center">
                            <h5 className='color-blue fw-bold m-fs-10'>Faturachman Asyari Oktavian</h5>
                            <h6 className='color-blue m-fs-8 lh-10'>UI/UX Designer</h6>
                            <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar Sarjana Teknik pada bidang Teknik Komputer Universitas Diponegoro, Semarang. Pengalaman 3 tahun dalam bidang UI dan UX Design.</p>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="card mx-1">
                        <img id='team-img' src={ Putri } className="mx-auto d-block mt-4" alt="Putri" />
                        <div className="card-body text-center">
                            <h5 className='color-blue fw-bold m-fs-10'>Putri Husnul Aprilia</h5>
                            <h6 className='color-blue m-fs-8 lh-10'>Sales</h6>
                            <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar sarjana sosial (S.sos) pada tahun 2019 dan berpengalaman sebagai asisten konsultan managemen dan Business Development selama dua tahun.</p>
                        </div>
                    </div>
                </div>
                </Carousel.Item>
                {/* 7 */}
                <Carousel.Item>
                    <div className='d-flex flex-row justify-content-center mt-5'>
                        {/* Card 1 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Hadistian } className="mx-auto d-block mt-4" alt="Hadistian" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Hadistian S.Pd</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Literacy Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar sarjana pendidikannya pada bidang Pendidikan Geografi di Universitas Negeri Jakarta. Pernah menjadi kontributor pada rubrik mahasiswa serta opini di Koran Sindo dan Media Indonesia.</p>
                            </div>
                        </div>
                        {/* Card 2 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Ihsan } className="mx-auto d-block mt-4" alt="Ihsan" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Muhammad Khairul Ihsan S.Si</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Numeration Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar sarjana sains pada bidang Matematika di Universitas Hasanuddin. Pengalaman 10 tahun dalam pengajaran bidang matematika dan pelatih Olimpiade nasional.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                {/* 8 */}
                <Carousel.Item>
                    <div className='d-flex flex-row justify-content-center mt-5'>
                        {/* Card 1 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Dimas } className="mx-auto d-block mt-4" alt="Dimas" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Dimas Widianto Ramadhan S.Si</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Numeration Content Team</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Meraih gelar sarjana sains pada bidang Fisika Instrumentasi di Universitas Islam Negeri Syarif Hidayatullah Jakarta. Pernah menjadi asisten laboratorium fisika di Pusat Laboratorium Terpadu UIN Jakarta.</p>
                            </div>
                        </div>
                        {/* Card 2 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Daniel } className="mx-auto d-block mt-4" alt="Daniel" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Daniel Saputra</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Full Stack Developer</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Berpengalaman sebagai full stack developer dengan keterampilan: Backend development, Web Applications, Data scraping, Cloud Computing, and IT Automation.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                {/* 9 */}
                <Carousel.Item>
                    <div className='d-flex flex-row justify-content-center mt-5'>
                        {/* Card 1 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Fadella } className="mx-auto d-block mt-4" alt="Fadella" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Fadella Amirah Siregar</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Full Stack Developer</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Berpengalaman sebagai full stack web developer dengan keterampilan: REST API, Socket.io, NodeJs, VueJs, ReactJs, ExpressJs, Git / Github, MySQL, Redis, AWS.</p>
                            </div>
                        </div>
                        {/* Card 2 */}
                        <div className="card mx-1">
                            <img id='team-img' src={ Ikrima } className="mx-auto d-block mt-4" alt="Ikrima" />
                            <div className="card-body text-center">
                                <h5 className='color-blue fw-bold m-fs-10'>Ikrima Amanda Wulandari</h5>
                                <h6 className='color-blue m-fs-8 lh-10'>Android Developer</h6>
                                <p className="card-text text-grey lh-10 m-fs-8">Berpengalaman 2 tahun sebagai pengembang aplikasi android dengan skills:Kotlin, API, XML, Android studio, Git / Github, MVVM, MVP, Firebase Realtime DB, NodeJS, ExpressJS, MySQL.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default TeamMobile