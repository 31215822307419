import { useState } from "react";
import { Row, Col } from "react-bootstrap"
import Content3Mobile from "./Content-3-mobile";
import ImageContent1 from '../../assets/images/Content3.png'
import ImageContent2 from '../../assets/images/Content3-2.png'
import ImageContent3 from '../../assets/images/Content3-3.png'
import ImageContent4 from '../../assets/images/Content3-4.png'
import ImageContent5 from '../../assets/images/Content3-5.png'

const Content3 = () => {
    const isMobile = window.innerWidth <= 500

    const isActive = {
        screen1: true,
        screen2: false,
        screen3: false,
        screen4: false,
        screen5: false
    }

    const [screenStatus, setScreenStatus] = useState(isActive)

    const changeScreen = (screen_default, screen_position) => {
        setScreenStatus({...isActive, [screen_position]: true, [screen_default]: false})
    }
    
    const imageContent = () => {
        let asArray = Object.entries(screenStatus)
        const filtered = asArray.filter(([key, value]) => value === true)
        const justStrings = Object.fromEntries(filtered)
        let screenResult = Object.keys(justStrings)
        screenResult = screenResult.toString()
        return screenResult
    }

    const imageScreen = {
        screen1: ImageContent1,
        screen2: ImageContent2,
        screen3: ImageContent3,
        screen4: ImageContent4,
        screen5: ImageContent5
    }

    if(isMobile) {
        return(
            <Content3Mobile />
        )
    } else {
        return(
            <div>
                <h6 className="fw-bold fs-30 color-blue text-center my-5 py-3">Yuk Mulai Pakai Eureka Edutech !</h6>
                <div className="d-flex justify-content-center">
                    <Row style={{ "marginLeft": "0", "marginRight": "0" }}>
                        <Col id="removePadding">
                        {
                            <img className="img-content3" aria-label="logo" src={ imageScreen[imageContent()] } />
                        }
                        </Col>
                    </Row>
                    <Row className="ms-4 mb-5">
                        <div className="container-fluid">
                            {/* 1 */}
                            <div id={screenStatus.screen1 ? 'backgroundRounded': 'backgroundRounded-off'} onClick={() => changeScreen('', 'screen1')} className="row ">
                                <div className="col-lg-2">
                                    <div id={screenStatus.screen1 ? 'rounded-on': 'rounded'} onClick={() => changeScreen('', 'screen1')} className="lh-160 ms-4 fw-bold">1</div>
                                    <div className="vl"></div>
                                </div>
                                <div className="col-lg-10">
                                    <p className="lh-160 nowrap mt-2 ms-3 fw-bold">Download Aplikasi "Eureka Edutech" di Playstore</p>
                                </div>
                            </div>
                            {/* 2 */}
                            <div id={screenStatus.screen2 ? 'backgroundRounded': 'backgroundRounded-off'} onClick={() => changeScreen('screen1', 'screen2')} className="row" style={{"marginTop": "23px"}}>
                                <div className="col-lg-2">
                                    <div id={screenStatus.screen2 ? 'rounded-on': 'rounded'} onClick={() => changeScreen('screen1', 'screen2')} className="lh-160 ms-4 fw-bold">2</div>
                                    <div className="vl"></div>
                                </div>
                                <div className="col-lg-10">
                                    <p className="lh-160 nowrap mt-2 ms-3 fw-bold">Lakukan Login atau Registrasi bagi yang belum memiliki akun</p>
                                </div>
                            </div>
                            {/* 3 */}
                            <div id={screenStatus.screen3 ? 'backgroundRounded': 'backgroundRounded-off'} onClick={() => changeScreen('screen1', 'screen3')} className="row" style={{"marginTop": "23px"}}>
                                <div className="col-lg-2">
                                    <div id={screenStatus.screen3 ? 'rounded-on': 'rounded'} onClick={() => changeScreen('screen1', 'screen3')} className="lh-160 ms-4 fw-bold">3</div>
                                    <div className="vl"></div>
                                </div>
                                <div className="col-lg-10">
                                    <p className="lh-160 nowrap mt-2 ms-3 fw-bold">Pilih Materi Pembelajaran yang Kamu Inginkan</p>
                                </div>
                            </div>
                            {/* 4 */}
                            <div id={screenStatus.screen4 ? 'backgroundRounded': 'backgroundRounded-off'} onClick={() => changeScreen('screen1', 'screen4')} className="row" style={{"marginTop": "23px"}}>
                                <div className="col-lg-2">
                                    <div id={screenStatus.screen4 ? 'rounded-on': 'rounded'} onClick={() => changeScreen('screen1', 'screen4')} className="lh-160 ms-4 fw-bold">4</div>
                                    <div className="vl"></div>
                                </div>
                                <div className="col-lg-10">
                                    <p className="lh-160 nowrap mt-2 ms-3 fw-bold">Ada Materi, Latihan Soal dan Ujian pada tiap Topik Pembelajaran</p>
                                </div>
                            </div>
                            {/* 5 */}
                            <div id={screenStatus.screen5 ? 'backgroundRounded': 'backgroundRounded-off'} onClick={() => changeScreen('screen1', 'screen5')} className="row" style={{"marginTop": "23px"}}>
                                <div className="col-lg-2">
                                    <div id={screenStatus.screen5 ? 'rounded-on': 'rounded'} onClick={() => changeScreen('screen1', 'screen5')} className="lh-160 ms-4 fw-bold">5</div>
                                </div>
                                <div className="col-lg-10">
                                    <p className="lh-160 nowrap mt-2 ms-3 fw-bold">Terdapat kuis harian yang dapat kamu kerjakan setiap hari, juga lho !</p>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Content3