import { Link } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";

const Content2 = () => {
    return(
        <div className="content2Policy mt-3">
            <Link to='/' className="text-decoration">
                <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 text-grey linkHome">Beranda</h6>
            </Link>
            <BsFillCaretRightFill className="text-grey mx-2" size={ 12 } />
            <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 blue-text">Kebijakan Privasi</h6>
        </div>
    )
}

export default Content2