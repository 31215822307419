import { Col } from 'react-bootstrap';
import '../../assets/styles/styles.css'
import GooglePlay from '../../assets/images/GooglePlay.svg'

const Banner = () => {
    return (
        <Col className='banner'>
            <h1 className='fw-bold fs-42 white-text m-fs-24'>Eureka Edutech</h1>
            <h5 className='fs-22 m-fs-16 white-text mt-4'>
                Persiapan asesmen nasional, belajar materi <br />
                literasi dan numerasi bareng Eureka Edutech!
            </h5>
            <a className="text-decoration d-none d-sm-block" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                <object className="mt-3 logoGooglePlayBanner pointer-events" type="image/svg+xml" aria-label="logo" data={ GooglePlay }></object>
            </a>
        </Col>
    )
}

export default Banner;