import '../../assets/styles/styles.css'
import EurekaLogo from '../../assets/images/LogoEureka.png'

const Description = () => {
    return (
        <div>
            <div className="gradient text-center white-text fs-30 m-fs-18 fw-bold">Tentang Kami</div>
            <img src={EurekaLogo} alt='Eureka' className='logoAbout mx-auto d-block my-4'></img>
            <div id='description' className='lh-160 text-grey m-fs-12'>
                <b className='m-fs-12'>PT. Edukasi Rekanan Anda (Eureka Edutech)</b> adalah perusahaan teknologi pendidikan yang berfokus kepada asesmen nasional untuk siswa di Indonesia yang didirikan berdasarkan hukum yang berlaku di Indonesia pada tahun 2020 di Jakarta Selatan. Eureka Edutech didirikan oleh sekumpulan penggiat pendidikan lulusan Ph.D luar negeri yang terdiri dari berbagai latar belakang profesi seperti pembina tim olimpiade nasional maupun internasional, pengusaha, penggiat literasi dan konsultan pendidikan. <br /> <br />

Eureka Edutech ingin menjawab tantangan pendidikan nasional secara holistik, terutama yang mengacu pada hasil pemeringkatan PISA (OECD) yang menjadi acuan seberapa baik pemahaman siswa-siswi di suatu negara dalam kemampuan memahami bacaan/ membaca, ilmu matematika serta sains. <br /> <br />

Dengan terbitnya PERATURAN MENTERI PENDIDIKAN, KEBUDAYAAN, RISET, DAN TEKNOLOGI REPUBLIK INDONESIA, NOMOR 17 TAHUN 2021, TENTANG ASESMEN NASIONAL, maka babak baru dunia pendidikan dasar dan menengah di Indonesia dimulai. Dengan spirit Merdeka Belajar, maka UN atau Ujian Nasional resmi diganti dengan pendekatan Asesmen Nasional. <br /> <br />

Eureka Edutech ingin menggaris bawahi menyangkut PerMen tersebut terkait tujuan dari Asesmen Nasional: <br />
<ol>
    <li className='lh-160 m-fs-12'>Mendorong guru mengembangkan kompetensi kognitif yang mendasar sekaligus karakter murid secara utuh.</li>
    <li className='lh-160 m-fs-12'>Menunjukkan apa yang seharusnya menjadi tujuan utama sekolah, yakni pengembangan kompetensi dan karakter murid.</li>
    <li className='lh-160 m-fs-12'>Memberi gambaran tentang karakteristik esensial sekolah yang efektif untuk mencapai tujuan tersebut. Ukuran kompetensi kognitif seperti tersebut di atas mencakup literasi membaca dan numerasi.</li>
</ol>

Eureka Edutech mengembangkan aplikasi pembelajaran untuk mempersiapkan siswa menjadi pembelajar yang lebih baik dalam bidang literasi serta numerasi, yang juga bermanfaat bagi mereka yang masuk atau terpilih untuk mengikuti proses Asesmen Kompetensi Minimum melalui sekolah mereka masing-masing. Apa yang dapat kami persembahkan bagi generasi muda kita yang masih duduk dan berjuang di bangku pendidikan selain bank soal yang relevan, yang mendorong kemampuan kognitif mereka, yang pada akhirnya akan membentuk kekuatan keunggulan generasi muda kita menyongsong masa depan dengan daya saing yang lebih tinggi.

            </div>
        </div>
    )
}

export default Description