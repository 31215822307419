const Banner = () => {
    return (
        <div className="bannerProductBook">
            <h5 className="text-orange fw-bold fs-42 m-fs-24 lh-130 border-white-text">Buku Panduan <br /> Asesmen Nasional 2022</h5> <br />
            <h6 className="white-text fw-500 fs-22 m-fs-16 lh-160">Persiapan asesmen nasional, belajar materi <br /> literasi dan numerasi bareng Eureka <br className="d-block d-sm-none" /> Edutech!</h6>
            <a className="text-decoration" href="https://api.whatsapp.com/send?phone=628111451220" target="_blank" rel="noreferrer">
                <button className="btnAppEurekaFill py-1 text-white fw-bold m-fs-12">Pesan Sekarang !</button>
            </a>
        </div>
    )
}

export default Banner