import AppProduct from '../../../assets/images/AppProduct.svg'
import VectorHp from '../../../assets/images/VectorHp.svg'

const Banner = () => {
    return (
        <div className='bannerApp'>
            <div className="container-fluid">
                <div className="row">
                    {/* Col */}
                    <div className="col-lg-1 d-none d-sm-block ms-4"></div>
                    <div id='textAppProduct' className="col-lg-5 col-md-5 col-12">
                        <h1 className='fw-bold fs-42 m-fs-24 white-text lh-160 text-center text-lg-start text-md-start'>Eureka Edutech App</h1>
                        <h5 className='fs-22 m-fs-16 white-text mt-4 fw-500 lh-160 text-center text-lg-start text-md-start'>
                            Belajar dimana Saja dan Kapan Saja bareng <br /> Eureka Edutech.
                        </h5>
                        {/* Button */}
                        <a className="text-decoration d-none d-sm-block" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                            <button className='btnAppEureka text-orange lh-160 fw-bold fs-16 px-3 py-1 mt-3'>
                                <img src={VectorHp} alt='Eureka' className='m-2' />
                                Coba Aplikasinya, GRATIS
                            </button>
                        </a>
                    </div>
                    {/* Col */}
                    <div className="col-lg-5 d-none d-sm-block">
                        <div className='imgAppProduct'>
                            <img src={AppProduct} alt='Eureka' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner