import { BsChevronDown } from "react-icons/bs"
import { BsChevronUp } from "react-icons/bs"
import { useState } from "react"

const Content4 = () => {
    const dropDownText = {
        text1: false,
        text2: false,
        text3: false,
        text4: false,
        text5: false,
        text6: false,
        text7: false,
        text8: false
    }

    const [textShow, setTextShow] = useState(dropDownText)

    const showingText = (showPosition) => {
        setTextShow({...dropDownText, [showPosition]: true})
    }
    
    return(
        <div>
            <br />
            <h6 className="color-blue fw-bold fs-30 m-fs-16 text-center my-5">Pertanyaanmu Ada Disini</h6>
            <div className="d-flex justify-content-center">
                <div className="container-fluid">
                    {/* 1 */}
                    <div id="box-content4" className="row">
                        <div className="col-lg-12 col-12">
                            <div className="row pointer" onClick={() => showingText('text1')}>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Apa itu aplikasi Mobile EUREKA ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text1 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <div className={textShow.text1 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Sebuah aplikasi yang dapat membantu siswa untuk belajar materi seputar
                                literasi, numerasi, dan sains dengan mudah. Kurikulum dalam aplikasi ini mengacu pada
                                indikator utama yang diujikan dalam penilaian nasional 2022 untuk mendukung
                                peningkatan peringkat PISA Indonesia.
                            </div>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 2 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text2')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Siapa saja yang bisa menggunakan aplikasi EUREKA ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text2 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text2 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Siapapun bisa menggunakan layanan EUREKA untuk mendukung kegiatan belajar dan persiapan asesmen nasional, 
                                saat ini layanan baru tersedia untuk tingkatan SMA.
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 3 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text3')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Bagaimana cara mendaftarkan diri pada layanan EUREKA ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text3 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text3 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Untuk saat ini pendaftaran layanan tersedia hanya pada aplikasi EUREKA versi android yang bisa diunduh melalui playstore.
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 4 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text4')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Apakah selain siswa SMA boleh menggunakan aplikasi EUREKA ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                    { textShow.text4 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text4 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Materi yang tersedia pada platform EUREKA dirancang untuk mempersiapkan siswa SMA menghadapi asesmen nasional yang tergolong baru di Indonesia. Platform untuk guru saat ini masih dalam tahap perancangan, namun untuk guru yang berkepentingan dalam kegiatan belajar mengajar boleh mengakses aplikasi EUREKA.
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 5 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text5')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Apa saja fitur yang dimiliki oleh aplikasi EUREKA ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                    { textShow.text5 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <div className={textShow.text5 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Aplikasi EUREKA memiliki beberapa fitur: <br />
                            <ol>
                                <li className="mt-1 m-fs-10">Materi Pembelajaran</li>
                                <div className="mt-1 mb-3 m-fs-10">Fitur ini berisi kumpulan modul-modul pembelajaran sebagai panduan</div>
                                <li className="m-fs-10">Latihan soal</li>
                                <div className="mt-1 mb-3 m-fs-10">Fitur ini berisi kumpulan soal sebagai bahan praktik untuk melatih kemampuan siswa sebelum melakukan ujian simulasi</div>
                                <li className="m-fs-10">Ujian</li>
                                <div className="mt-1 mb-3 m-fs-10">Fitur ini berisi soal-soal untuk mengukur pemahaman dan kemampuan siswa atas materi numerasi dan literasi yang telah dipelajari</div>
                            </ol>
                            </div>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 6 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text6')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Bagaimana cara menghubungi layanan pelanggan EUREKA ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text6 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <div className={textShow.text6 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Untuk layanan pelanggan, kamu bisa menghubungi beberapa dari kami pada platform berikut: <br />
                                <ul>
                                    <li className="m-fs-10">Email &emsp;&emsp;&emsp; :&emsp; info@eurekaedutech.com </li>
                                    <li className="m-fs-10">Twitter&emsp;&emsp;&emsp;:&emsp; @eureka_edutech </li>
                                    <li className="m-fs-10">Instagram &emsp;:&emsp; @eureka.edutech</li>
                                </ul>
                            </div>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 7 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text7')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Bagaimana cara untuk melakukan pengaduan konsumen ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text7 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text7 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Apabila kamu mengalami kendala, kamu dapat langsung menghubungi kami pada kontak berikut: <br />
                                Email : info@eurekaedutech.com
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 8 */}
                    <div id="box-content4" className="row pointer mb-5" onClick={() => showingText('text8')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">Apakah siswa SMK dan mahasiswa bisa menggunakan aplikasi EUREKA ?</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text8 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text8 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                Bisa, karena ini adalah aplikasi belajar dan siapa pun bisa menggunakannya, 
                                soal untuk asesmen nasional juga diperlukan bagi semua pelajar.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content4